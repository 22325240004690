import { BaseType, getBaseData } from './base';

export interface AttachmentType extends BaseType {
  signed_id: string;
  url: string;
  content_type: string;
  filename: string;
  byte_size: number;
}

export function getAttachmentDefaultData(): AttachmentType {
  return {
    ...getBaseData(),
    signed_id: '',
    url: '',
    content_type: '',
    filename: '',
    byte_size: 0
  };
}
