import { BaseType, getBaseData } from './base';
import { AUTOMATION_COMPAIGN_STATE, COMPAIGN_BID_STRATEGY_TYPE, CAMPAIGN_TYPES } from '@/constants';


export interface DetailedProductType {
  category_name: string;
  path: string;
}

export interface Keyword extends BaseType {
  custom_parameter: string;
  final_url: string;
  final_url_suffix: string;
  keyword: string;
  keyword_type: string;
  label: string;
  mobile_final_url: string;
  tracking_template: string;
  default_max_cpc: number;
  max_cpv: number;
  keyword_status: string;
  impressions: number;
  ctr: number;
  cpc: string;
  clicks: number;
  cost: string;
  monthly_searches: string;
  competition_index: number;
  low_top_of_page_bid: string;
  high_top_of_page_bid: string;
  _destroy?: true;
}

export interface ResponsiveSearchAd extends BaseType {
  ad_status: string;
  ad_type: string;
  custom_parameter: string;
  description: string;
  description_2: string;
  description_3: string;
  description_4: string;
  final_url: string;
  final_url_suffix: string;
  headline_1: string;
  headline_10: string;
  headline_11: string;
  headline_12: string;
  headline_13: string;
  headline_14: string;
  headline_15: string;
  headline_2: string;
  headline_3: string;
  headline_4: string;
  headline_5: string;
  headline_6: string;
  headline_7: string;
  headline_8: string;
  headline_9: string;
  label: string;
  mobile_final_url: string;
  path_1: string;
  path_2: string;
  tracking_template: string;
  ad_group_id: number;
  description_1_position: number;
  description_2_position: number;
  description_3_position: number;
  description_4_position: number;
  headline_10_position: number;
  headline_11_position: number;
  headline_12_position: number;
  headline_13_position: number;
  headline_14_position: number;
  headline_15_position: number;
  headline_1_position: number;
  headline_2_position: number;
  headline_3_position: number;
  headline_4_position: number;
  headline_5_position: number;
  headline_6_position: number;
  headline_7_position: number;
  headline_8_position: number;
  headline_9_position: number;
  backup_descriptions?: string[];
  backup_headlines?: string[];
  $headlines?: string[];
  $descriptions?: string[];
}

export interface AdGroup extends BaseType {
  ad_group: string;
  ad_group_status: string;
  ad_group_type: string;
  ad_rotation: string;
  custom_parameter: string;
  final_url_suffix: string;
  label: string;
  tracking_template: string;
  cpc_percentage: number;
  default_max_cpc: number;
  max_cpm: number;
  max_cpv: number;
  target_cpa: number;
  target_cpm: number;
  campaign_id: number;
  target_roas: number;
  keywords: Keyword[];
  responsive_search_ad: ResponsiveSearchAd;
  _destroy?: true;
}

interface GoogleCompaignType extends BaseType {
  bid_strategy: string;
  bid_strategy_type: typeof COMPAIGN_BID_STRATEGY_TYPE[number];
  budget_type: string;
  campaign: string;
  campaign_subtype: string;
  campaign_type: typeof CAMPAIGN_TYPES[number]['value'];
  custom_parameter: string;
  customer_id: string;
  delivery_method: string;
  devices: string;
  exclusion: string;
  final_url_suffix: string;
  inventory_type: string;
  label: string;
  language: string;
  location: string;
  location_goal_for_target_is: string;
  networks: string;
  tracking_template: string;
  viewability_vendor: string;
  budget: number;
  max_cpc_bid_limit_for_target_is: number | null;
  target_cpa: number | null;
  automation_campaign_id: number;
  target_impression_share: number;
  target_roas: number | null;
  campaign_end_date: string;
  campaign_start_date: string;
  campaign_status: string;
  ad_groups: Partial<AdGroup>[];
  display_network: boolean;
  cpc_bid_ceiling?: number;
}

export interface AutomationCampaignType extends BaseType {
  category_name: string;
  client_name: string;
  email: string;
  language_id: number;
  location_ids: number[];
  merchant_id: number;
  state: keyof typeof AUTOMATION_COMPAIGN_STATE;
  google_manager_id: string;
  google_customer_id: string;
  google_campaign?: Partial<GoogleCompaignType>;
  forecast_keywords: Keyword[];
  detailed_product_type?: Partial<DetailedProductType>[],
  seed_keywords?: Array<string>;
  home_page_url?: string;
  merchant_center_id?: string;
  synced: boolean;
  abilities: {
    export: boolean;
  };
}

export function getAutomationCampaignDefaultData(): AutomationCampaignType {
  return {
    ...getBaseData(),
    category_name: '',
    client_name: '',
    email: '',
    language_id: 0,
    location_ids: [],
    merchant_id: 0,
    state: 'pending',
    google_manager_id: '',
    google_customer_id: '',
    forecast_keywords: [],
    synced: false,
    abilities: {
      export: false,
    }
  };
}
