import { authStore } from '@/stores';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export function useMainMenu() {
  const { t } = useI18n();

  const menus = computed(() => {
    const userAbility = authStore.user.abilities;
    return [
      {
        title: t('menu.merchants_management'),
        icon: 'user',
        route: { name: 'merchants.index' },
        if: userAbility?.read_merchant,
      },
      {
        title: t('menu.agencies_management'),
        icon: 'UserFilled',
        route: { name: 'agencies.index' },
        if: userAbility?.read_agency,
      },
      {
        title: t('menu.templates_management'),
        icon: 'document',
        route: { name: 'policy-templates.index' },
        if: userAbility?.create_policy_template,
      },
      {
        title: t('menu.appeal_record_management'),
        icon: 'Warning',
        route: { name: 'appeal-records' },
        if: userAbility?.read_appeal_record,
      },
      {
        title: t('menu.help_center'),
        icon: 'QuestionFilled',
        route: { name: 'help-center' },
        if: true,
      }
    ].filter(item => item.if);
  });

  return {
    menus
  };
}
