import { type RouteRecordRaw, type RouteLocationNormalized, type NavigationGuardNext } from 'vue-router';
import { defineComponent, inject } from 'vue';
import { request } from '@/utils';
import { type MerchantType } from '@/models/merchant';

function beforeNewCampaignEnter(this: undefined, to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
  const { google_manager_id, google_customer_id } = to.query;
  if (!google_manager_id || !google_customer_id) {
    next({ name: 'gads.campaigns.new', params: to.params });
    return;
  }
  next();
}

const Layout = defineComponent({
  setup() {
    const merchant = inject<MerchantType>('merchant');
    if (merchant) {
      // 做统计
      if (!merchant.first_visit_google_campaign_page_at) {
        request.put(`merchants/${merchant.id}/update_first_visit_google_campaign_page_at`);
      }
    }
  },
  template: '<router-view />'
});

export const gadsRoute: RouteRecordRaw = {
  path: 'gads/campaigns',
  component: Layout,
  children: [
    {
      path: '',
      component: require('@/views/gads/campaigns/index.vue').default,
      name: 'gads.campaigns',
      meta: {
        contentHeightFixed: true
      }
    },
    {
      path: ':campaignId',
      component: require('@/views/gads/campaigns/show.vue').default,
      name: 'gads.campaigns.show',
    },
    {
      path: ':campaignId/shopping',
      name: 'gads.campaigns.show.shopping',
      component: require('@/views/gads/campaigns/shopping/show.vue').default
    },
    {
      path: ':campaignId/search',
      component: require('@/views/gads/campaigns/search/show/layout.vue').default,
      children: [
        {
          path: '',
          name: 'gads.campaigns.show.search',
          component: require('@/views/gads/campaigns/search/show/campaign.vue').default
        },
        {
          path: 'keywords',
          name: 'gads.campaigns.show.search.keywords',
          component: require('@/views/gads/campaigns/search/show/keyword-view.vue').default
        }
      ],
    },
    {
      path: 'new',
      component: require('@/views/gads/campaigns/new.vue').default,
      name: 'gads.campaigns.new',
      meta: {
        routerViewCardWrapper: false,
      }
    },
    {
      path: 'new/shopping',
      component: require('@/views/gads/campaigns/shopping/new.vue').default,
      name: 'gads.campaigns.new.shopping',
      meta: {
        routerViewCardWrapper: false,
      },
      beforeEnter: beforeNewCampaignEnter
    },
    {
      path: 'new/search',
      component: require('@/views/gads/campaigns/search/new.vue').default,
      name: 'gads.campaigns.new.search',
      meta: {
        routerViewCardWrapper: false,
      },
      beforeEnter: beforeNewCampaignEnter
    },
  ]
};
