import { AxiosResponseHeaders } from 'axios';
import { ElMessageBox } from 'element-plus';
import { request } from './request';
import i18n from '@/i18n';

function getAttachmentNameFromHeader(header: AxiosResponseHeaders) {
  let filename = '';
  const disposition = header['content-disposition'] || header['Content-Disposition'];
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches !== null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return decodeURI(filename).replace(/^utf-8/i, '');
}

export async function exportFile(url: string) {
  const { data, headers } = await request.get(url, { responseType: 'blob', timeout: 5 * 60 * 1000 });
  const href = URL.createObjectURL(data);
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = href;
  link.setAttribute('download', getAttachmentNameFromHeader(headers) || 'export.xlsx');
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(href);
  document.body.removeChild(link);
}

export async function exportGoogleAdCampaign(merchantId: number | string, campaignId: number | string) {
  const url = `merchants/${merchantId}/automation_campaigns/${campaignId}/export`;
    try {
    await request.put(`merchants/${merchantId}/automation_campaigns/${campaignId}/check_google_import`, {}, { loading: { silent: true } });
    return exportFile(url);
  } catch (e: any) {
    if (e.message) {
      await ElMessageBox.confirm(e.message, {
        confirmButtonText: i18n.global.t('gads.continuing_export')
      });
      return exportFile(url);
    }
  }
}
